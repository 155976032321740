export function gbRepresentativeExample(_a) {
    var productType = _a.productType, defaultProductId = _a.defaultProductId;
    if (productType === 'gb_pch_toyota') {
        return [
            {
                componentId: 'rightBoxContainer_' + defaultProductId,
                testId: 'rightBoxContainer',
                componentType: 'TemplateComponent',
                containerId: 'onlineApprovalContainer_content',
                componentTemplate: 'componentContainer',
                outsideEntityContainer: '.finance-aside',
                outerContainerId: '#externalBox',
                level: 1,
                order: 5,
                productId: [defaultProductId, 'general'],
            },
            {
                componentId: 'rightBox',
                testId: 'rightBox',
                componentType: 'TemplateComponent',
                containerId: 'navigationTabsOnlineApproval_tab-content-rightBox',
                componentTemplate: 'rightBoxGBTemplate',
                outerContainerId: '#rightBoxContainer_' + defaultProductId + '_content',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-right-box bss-right-box--flex-column-reverse',
                level: 1,
                order: 7,
                productId: [defaultProductId, 'general'],
                title: "<value data-text='general.your_quote_label'></value>",
                tableTitle: "<value data-text='general.options_title_label'></value>",
                model: {
                    name: "<value data-value='" +
                        defaultProductId +
                        ".components.labelComponents.gbModelBodyGrade.value'></value>",
                    type: "<value data-value='" +
                        defaultProductId +
                        ".components.labelComponents.engineShortName.value'></value>",
                    engine: "<value data-value='" +
                        defaultProductId +
                        ".components.labelComponents.transmissionName.value'></value>",
                },
                simulator: [
                    {
                        title: "<value data-text='general.first_monthly_payment'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.firstMonthlyPayment.value'></value>",
                    },
                    {
                        title: "<value data-text='general.monthly_payment_label'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.monthlyPayment.value'></value>",
                    },
                ],
                secure: {
                    title: "<value data-text='general.secure_checkout_header'></value>",
                    description: "<value data-text='general.secure_checkout_text'></value>",
                },
            },
            {
                class: 'bss-button_btn--simple-print bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right bss-mb-2 rightBox_bss-print-button',
                componentId: 'rightBox_bss-print-button',
                testId: 'rightBox_bss-print-button',
                componentType: 'ButtonComponent',
                componentTemplate: 'link',
                containerId: 'rightBox',
                isPublicEvent: true,
                level: 2,
                onClickEvent: 'bss-button_btn--print-event',
                order: 1,
                outerContainerId: '#rightBox',
                outsideEntityContainer: '.finance-aside',
                symbol: {
                    'symbol-before': '&#xE808',
                },
                productId: [defaultProductId, 'general'],
                sendEventToServer: false,
                text: "<value data-text='general.print_your_order_button_label'></value>",
                watcher: [
                    {
                        watcherStateType: 'AND',
                        boundDataOperand: 'operandRight',
                        effectSuccess: ['show'],
                        effectFail: ['hide'],
                        operandLeft: ['failure', 'true'],
                        operator: ['neq', 'eq'],
                        operandRight: [
                            defaultProductId + '.submissionStatus',
                            defaultProductId + '.offline',
                        ],
                    },
                ],
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label2InfoHint',
                testId: 'label2InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_2_info-hint-container',
                outerContainerId: '#rightBox_2_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 1,
                hintText: "<value data-text='general.trade_in_value_infohint'></value>",
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label5InfoHint',
                testId: 'label5InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_5_info-hint-container',
                outerContainerId: '#rightBox_5_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 2,
                hintText: "<value data-text='general.allowance_infohint'></value>",
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label7InfoHint',
                testId: 'label7InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_7_info-hint-container',
                outerContainerId: '#rightBox_7_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 3,
                hintText: "<value data-text='general.guaranteed_fv_infohint'></value>",
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label9InfoHint',
                testId: 'label9InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_9_info-hint-container',
                outerContainerId: '#rightBox_9_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 4,
                hintText: "<value data-text='general.term_infohint'></value>",
            },
            {
                componentId: 'yourQuoteSummary',
                testId: 'yourQuoteSummary',
                containerId: 'rightBox_article',
                outerContainerId: '#rightBox_article',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-col-xs-12',
                componentType: 'LabelComponent',
                productId: [defaultProductId, 'general'],
                level: 2,
                order: 5,
                text: "<value data-text='general.your_quote_summary'></value>",
            },
            {
                componentId: 'rightBox_button1',
                testId: 'rightBox_button1',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-button_btn--primary--disabled',
                level: 2,
                order: 6,
                isPublicEvent: false,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnNextButton-navigationTabsOnlineApproval',
                text: "<value data-text='general.next_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
                watcher: [
                    {
                        boundDataOperand: 'subscriber',
                        effectFail: 'addClass',
                        effectSuccess: 'removeClass',
                        subscriberName: 'bss-step-navigation-on-form-change-@entityInstanceId@',
                        operandRespond: 'valid',
                        className: 'bss-button_btn--primary--disabled',
                    },
                    {
                        boundDataOperand: 'operandLeft',
                        effectFail: 'show',
                        effectSuccess: 'hide',
                        operandLeft: defaultProductId + '.submissionStatus',
                        operator: 'is',
                    },
                ],
            },
            {
                componentId: 'rightBox_button2',
                testId: 'rightBox_button2',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-watcher-hide',
                level: 2,
                order: 7,
                isPublicEvent: false,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnSubmitButton-navigationTabsOnlineApproval',
                text: "<value data-text='general.submit_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
            },
            {
                componentId: 'rightBox_button_submit-next',
                testId: 'rightBox_button_submit-next',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-watcher-hide',
                level: 2,
                order: 8,
                isPublicEvent: true,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnApproveButton',
                text: "<value data-text='general.scoring_next_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
                watcher: [
                    {
                        boundDataOperand: 'operandRight',
                        effectSuccess: 'show',
                        effectFail: 'hide',
                        operandLeft: 'approved',
                        operator: 'eq',
                        operandRight: defaultProductId + '.proposalStatus',
                    },
                ],
            },
            {
                componentId: 'rightBox_button_submit-save',
                testId: 'rightBox_button_submit-save',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-watcher-hide',
                level: 2,
                order: 9,
                isPublicEvent: true,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnApproveButton',
                text: "<value data-text='general.scoring_save_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
                watcher: [
                    {
                        boundDataOperand: 'operandRight',
                        effectSuccess: 'show',
                        effectFail: 'hide',
                        operandLeft: 'referred',
                        operator: 'eq',
                        operandRight: defaultProductId + '.proposalStatus',
                    },
                ],
            },
            {
                componentId: 'rightBox_image1',
                testId: 'rightBox_image1',
                componentType: 'ImageComponent',
                containerId: 'rightBox_image',
                outerContainerId: '#rightBox_image',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 10,
                src: "<value data-text='general.400x200'></value>",
                alt: "<value data-text='general.modelName'></value>",
                productId: [defaultProductId, 'general'],
            },
            {
                componentId: 'paks_accordionContainer',
                testId: 'paks_accordionContainer',
                componentType: 'AccordionComponent',
                containerId: 'bss-right-box_accessories-content',
                outerContainerId: '#bss-right-box_accessories-content',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-accordion_wrapper-with-list',
                level: 3,
                order: 0,
                productId: [defaultProductId, 'general'],
                arrowIcon: '&#xe804',
                animDuration: 400,
                title: "<value data-text='general.pakcs_title_label'></value>",
                resultDataFlat: true,
                listDataName: defaultProductId + '.components_labelComponents_packsNames_value',
                minListSize: 4,
            },
            {
                componentId: 'extras_accordionContainer',
                testId: 'extras_accordionContainer',
                componentType: 'AccordionComponent',
                containerId: 'bss-right-box_accessories-content',
                outerContainerId: '#bss-right-box_accessories-content',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-accordion_wrapper-with-list',
                level: 3,
                order: 1,
                productId: [defaultProductId, 'general'],
                arrowIcon: '&#xe804',
                animDuration: 400,
                title: "<value data-text='general.extras_title_label'></value>",
                resultDataFlat: true,
                listDataName: [
                    defaultProductId + '.components_labelComponents_optionsNames_value',
                    defaultProductId +
                        '.components_labelComponents_accessoriesNames_value',
                ],
                minListSize: 4,
            },
        ];
    }
    else {
        return [
            {
                componentId: 'rightBoxContainer_' + defaultProductId,
                testId: 'rightBoxContainer',
                componentType: 'TemplateComponent',
                containerId: 'onlineApprovalContainer_content',
                componentTemplate: 'componentContainer',
                outsideEntityContainer: '.finance-aside',
                outerContainerId: '#externalBox',
                level: 1,
                order: 5,
                productId: [defaultProductId, 'general'],
            },
            {
                componentId: 'rightBox',
                testId: 'rightBox',
                componentType: 'TemplateComponent',
                containerId: 'navigationTabsOnlineApproval_tab-content-rightBox',
                componentTemplate: 'rightBoxGBTemplate',
                outerContainerId: '#rightBoxContainer_' + defaultProductId + '_content',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-right-box bss-right-box--flex-column-reverse',
                level: 1,
                order: 7,
                productId: [defaultProductId, 'general'],
                title: "<value data-text='general.your_quote_label'></value>",
                tableTitle: "<value data-text='general.options_title_label'></value>",
                model: {
                    name: "<value data-value='" +
                        defaultProductId +
                        ".components.labelComponents.gbModelBodyGrade.value'></value>",
                    type: "<value data-value='" +
                        defaultProductId +
                        ".components.labelComponents.engineShortName.value'></value>",
                    engine: "<value data-value='" +
                        defaultProductId +
                        ".components.labelComponents.transmissionName.value'></value>",
                },
                simulator: [
                    {
                        title: "<value data-text='general.first_monthly_payment'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.firstMonthlyPayment.value'></value>",
                    },
                    {
                        title: "<value data-text='general.monthly_payment_label'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.monthlyPaymentResult.value'></value>",
                    },
                    {
                        title: "<value data-text='general.customer_deposit'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.downpaymentResult.value'></value>",
                    },
                    {
                        title: "<value data-text='general.trade_in_value'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.tradeInValue.value'></value>",
                        originValue: "<value data-value='" +
                            defaultProductId +
                            ".components.labelComponents.tradeInValue.value'></value>",
                    },
                    {
                        title: "<value data-text='general.cash_price'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.price.value'></value>",
                    },
                    {
                        title: "<value data-text='general.allowance_label'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.allowance.value'></value>",
                        originValue: "<value data-value='" +
                            defaultProductId +
                            ".components.labelComponents.allowance.value'></value>",
                    },
                    {
                        title: "<value data-text='general.amount_of_credit'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.creditAmount.value'></value>",
                    },
                    {
                        title: "<value data-text='general.guaranteed_fv'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.residualValue.value'></value>",
                    },
                    {
                        title: "<value data-text='general.total_amount_payable'></value>",
                        value: "<value data-formatter='gbCurrency2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.totalAmount.value'></value>",
                    },
                    {
                        title: "<value data-text='general.term'></value>",
                        value: "<value data-formatter='gbDuration' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.duration.value'></value>",
                    },
                    {
                        title: "<value data-text='general.fixed_rate_of_interest'></value>",
                        value: "<value data-formatter='gbPercent2Decimals' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.interestRate.value'></value>",
                    },
                    {
                        title: "<value data-text='general.representative'></value>",
                        value: "<value data-formatter='gbPercent2DecimalsAPR' data-value='" +
                            defaultProductId +
                            ".components.labelComponents.aprTarget.value'></value>",
                    },
                ],
                secure: {
                    title: "<value data-text='general.secure_checkout_header'></value>",
                    description: "<value data-text='general.secure_checkout_text'></value>",
                },
            },
            {
                class: 'bss-button_btn--simple-print bss-link-wrapper--uppercase bss-link-wrapper--no-sides bss-link-wrapper--pulled-right bss-mb-2 rightBox_bss-print-button',
                componentId: 'rightBox_bss-print-button',
                testId: 'rightBox_bss-print-button',
                componentType: 'ButtonComponent',
                componentTemplate: 'link',
                containerId: 'rightBox',
                isPublicEvent: true,
                level: 2,
                onClickEvent: 'bss-button_btn--print-event',
                order: 1,
                outerContainerId: '#rightBox',
                outsideEntityContainer: '.finance-aside',
                symbol: {
                    'symbol-before': '&#xE808',
                },
                productId: [defaultProductId, 'general'],
                sendEventToServer: false,
                text: "<value data-text='general.print_your_order_button_label'></value>",
                watcher: [
                    {
                        watcherStateType: 'AND',
                        boundDataOperand: 'operandRight',
                        effectSuccess: ['show'],
                        effectFail: ['hide'],
                        operandLeft: ['failure', 'true'],
                        operator: ['neq', 'eq'],
                        operandRight: [
                            defaultProductId + '.submissionStatus',
                            defaultProductId + '.offline',
                        ],
                    },
                ],
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label2InfoHint',
                testId: 'label2InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_2_info-hint-container',
                outerContainerId: '#rightBox_2_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 1,
                hintText: "<value data-text='general.trade_in_value_infohint'></value>",
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label5InfoHint',
                testId: 'label5InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_5_info-hint-container',
                outerContainerId: '#rightBox_5_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 2,
                hintText: "<value data-text='general.allowance_infohint'></value>",
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label7InfoHint',
                testId: 'label7InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_7_info-hint-container',
                outerContainerId: '#rightBox_7_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 3,
                hintText: "<value data-text='general.guaranteed_fv_infohint'></value>",
            },
            {
                productId: [defaultProductId, 'general'],
                componentId: 'label9InfoHint',
                testId: 'label9InfoHint',
                componentType: 'InfohintComponent',
                containerId: 'rightBox_9_info-hint-container',
                outerContainerId: '#rightBox_9_info-hint-container',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 4,
                hintText: "<value data-text='general.term_infohint'></value>",
            },
            {
                componentId: 'yourQuoteSummary',
                testId: 'yourQuoteSummary',
                containerId: 'rightBox_article',
                outerContainerId: '#rightBox_article',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-col-xs-12',
                componentType: 'LabelComponent',
                productId: [defaultProductId, 'general'],
                level: 2,
                order: 5,
                text: "<value data-text='general.your_quote_summary'></value>",
            },
            {
                componentId: 'rightBox_button1',
                testId: 'rightBox_button1',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-button_btn--primary--disabled',
                level: 2,
                order: 6,
                isPublicEvent: false,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnNextButton-navigationTabsOnlineApproval',
                text: "<value data-text='general.next_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
                watcher: [
                    {
                        boundDataOperand: 'subscriber',
                        effectFail: 'addClass',
                        effectSuccess: 'removeClass',
                        subscriberName: 'bss-step-navigation-on-form-change-@entityInstanceId@',
                        operandRespond: 'valid',
                        className: 'bss-button_btn--primary--disabled',
                    },
                    {
                        boundDataOperand: 'operandLeft',
                        effectFail: 'show',
                        effectSuccess: 'hide',
                        operandLeft: defaultProductId + '.submissionStatus',
                        operator: 'is',
                    },
                ],
            },
            {
                componentId: 'rightBox_button2',
                testId: 'rightBox_button2',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-watcher-hide',
                level: 2,
                order: 7,
                isPublicEvent: false,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnSubmitButton-navigationTabsOnlineApproval',
                text: "<value data-text='general.submit_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
            },
            {
                componentId: 'rightBox_button_submit-next',
                testId: 'rightBox_button_submit-next',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-watcher-hide',
                level: 2,
                order: 8,
                isPublicEvent: true,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnApproveButton',
                text: "<value data-text='general.scoring_next_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
                watcher: [
                    {
                        boundDataOperand: 'operandRight',
                        effectSuccess: 'show',
                        effectFail: 'hide',
                        operandLeft: 'approved',
                        operator: 'eq',
                        operandRight: defaultProductId + '.proposalStatus',
                    },
                ],
            },
            {
                componentId: 'rightBox_button_submit-save',
                testId: 'rightBox_button_submit-save',
                componentType: 'ButtonComponent',
                containerId: 'rightBox_button',
                outerContainerId: '#rightBox_button',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-button_btn--primary bss-button_btn--right-box bss-watcher-hide',
                level: 2,
                order: 9,
                isPublicEvent: true,
                newDesign: true,
                onClickEvent: 'bss-step-navigation-clickOnApproveButton',
                text: "<value data-text='general.scoring_save_button_label'></value>",
                sendEventToServer: false,
                productId: [defaultProductId, 'general'],
                watcher: [
                    {
                        boundDataOperand: 'operandRight',
                        effectSuccess: 'show',
                        effectFail: 'hide',
                        operandLeft: 'referred',
                        operator: 'eq',
                        operandRight: defaultProductId + '.proposalStatus',
                    },
                ],
            },
            {
                componentId: 'rightBox_image1',
                testId: 'rightBox_image1',
                componentType: 'ImageComponent',
                containerId: 'rightBox_image',
                outerContainerId: '#rightBox_image',
                outsideEntityContainer: '.finance-aside',
                level: 2,
                order: 10,
                src: "<value data-text='general.400x200'></value>",
                alt: "<value data-text='general.modelName'></value>",
                productId: [defaultProductId, 'general'],
            },
            {
                componentId: 'paks_accordionContainer',
                testId: 'paks_accordionContainer',
                componentType: 'AccordionComponent',
                containerId: 'bss-right-box_accessories-content',
                outerContainerId: '#bss-right-box_accessories-content',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-accordion_wrapper-with-list',
                level: 3,
                order: 0,
                productId: [defaultProductId, 'general'],
                arrowIcon: '&#xe804',
                animDuration: 400,
                title: "<value data-text='general.pakcs_title_label'></value>",
                resultDataFlat: true,
                listDataName: defaultProductId + '.components_labelComponents_packsNames_value',
                minListSize: 4,
            },
            {
                componentId: 'extras_accordionContainer',
                testId: 'extras_accordionContainer',
                componentType: 'AccordionComponent',
                containerId: 'bss-right-box_accessories-content',
                outerContainerId: '#bss-right-box_accessories-content',
                outsideEntityContainer: '.finance-aside',
                class: 'bss-accordion_wrapper-with-list',
                level: 3,
                order: 1,
                productId: [defaultProductId, 'general'],
                arrowIcon: '&#xe804',
                animDuration: 400,
                title: "<value data-text='general.extras_title_label'></value>",
                resultDataFlat: true,
                listDataName: [
                    defaultProductId + '.components_labelComponents_optionsNames_value',
                    defaultProductId +
                        '.components_labelComponents_accessoriesNames_value',
                ],
                minListSize: 4,
            },
        ];
    }
}
