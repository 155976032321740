export function gbStep1(_a) {
    var defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'titleFirstStep',
            testId: 'titleFirstStep',
            containerId: 'navigationTabsOnlineApproval_tab-content-0',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 2,
            order: 1,
            text: "<value data-text='general.personal_details'></value>",
            class: 'bss-label--step-navigation-title',
        },
        {
            componentId: 'componentContainer_personalDetailsLabel',
            testId: 'componentContainer_personalDetailsLabel',
            componentType: 'TemplateComponent',
            componentTemplate: 'componentContainer',
            level: 2,
            order: 3,
            containerId: 'navigationTabsOnlineApproval_tab-content-0',
            productId: [defaultProductId, 'general'],
            isParentDependentChild: true,
            class: 'bss-component-container-wrapper--step-navigation bss-m-0 bss-mb-3 visible-online',
        },
        {
            componentId: 'firstStepFormGrid',
            testId: 'firstStepFormGrid',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-0',
            productId: [defaultProductId, 'general'],
            level: 2,
            order: 5,
            componentTemplate: 'grid',
            columns: [
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
                'bss-col-xs-12',
            ],
            class: 'bss-p-0 grid-class',
        },
        {
            componentId: 'dropdownTitle',
            testId: 'dropdownTitle',
            containerId: 'firstStepFormGrid_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 1,
            required: true,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.title'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--required bss-mb-3 bss-dropdown--full-width bss-dropdown_label-required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_personalDetailsLabel_content',
            labelClass: 'bss-label--step-navigation-description bss-inline-block',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'title-error-required',
                name: 'title',
            },
            selectErrors: [
                {
                    id: 'title-error-required',
                    text: "<value data-text='general.error_empty_title'></value>",
                },
            ],
            title: "<value data-text='general.title'></value>",
            customSelect: true,
        },
        {
            componentId: 'textInput_firstName',
            testId: 'textInput_firstName',
            componentType: 'TextInputComponent',
            containerId: 'firstStepFormGrid_col-1',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 3,
            onChangeOff: true,
            label: "<value data-text='general.firstName'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.firstName.value'></value>",
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_personalDetailsLabel_content',
            class: 'bss-mb-3 bss-p-0 bss-text-input--required bss-text-input--label-uppercase',
            labelClass: 'bss-label--step-navigation-description bss-inline-block',
            inputAttributes: {
                maxlength: 40,
                'data-rule-required': true,
                'data-rule-regular': true,
                'data-error-required': 'firstName-error-required',
                'data-error-regular': 'firstName-error-invalid',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]{2,}$',
                name: 'firstName',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'firstName-error-invalid',
                    text: "<value data-text='general.error_invalid_firstName'></value>",
                },
                {
                    id: 'firstName-error-required',
                    text: "<value data-text='general.error_empty_firstName'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_surname',
            testId: 'textInput_surname',
            componentType: 'TextInputComponent',
            containerId: 'firstStepFormGrid_col-2',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 4,
            onChangeOff: true,
            label: "<value data-text='general.lastName'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.lastName.value'></value>",
            required: true,
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_personalDetailsLabel_content',
            class: 'bss-mb-3 bss-p-0 bss-text-input--required bss-text-input--label-uppercase',
            labelClass: 'bss-label--step-navigation-description bss-inline-block',
            inputAttributes: {
                maxlength: 30,
                'data-rule-required': true,
                'data-rule-regular': true,
                'data-error-regular': 'surname-error-invalid',
                'data-error-required': 'surname-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]{2,}$',
                name: 'lastName',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'surname-error-invalid',
                    text: "<value data-text='general.error_invalid_lastName'></value>",
                },
                {
                    id: 'surname-error-required',
                    text: "<value data-text='general.error_empty_lastName'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_phone',
            testId: 'textInput_phone',
            componentType: 'TextInputComponent',
            containerId: 'firstStepFormGrid_col-3',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 5,
            onChangeOff: true,
            label: "<value data-text='general.phoneNumber'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.phoneNumber.value'></value>",
            required: true,
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_personalDetailsLabel_content',
            class: 'bss-mb-3 bss-p-0 bss-text-input--required bss-text-input--label-uppercase',
            labelClass: 'bss-label--step-navigation-description',
            inputAttributes: {
                maxlength: 11,
                'data-rule-required': true,
                'data-rule-regular': true,
                'data-error-required': 'phoneNumber-error-required',
                'data-error-regular': 'phoneNumber-error-invalid',
                'data-regular': '^[0-9]{11}$',
                name: 'phoneNumber',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'phoneNumber-error-required',
                    text: "<value data-text='general.error_empty_phoneNumber'></value>",
                },
                {
                    id: 'phoneNumber-error-invalid',
                    text: "<value data-text='general.error_invalid_phoneNumber'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_email',
            testId: 'textInput_email',
            componentType: 'TextInputComponent',
            containerId: 'firstStepFormGrid_col-4',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.email'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.email.value'></value>",
            required: true,
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_personalDetailsLabel_content',
            class: 'bss-mb-3 bss-p-0 bss-text-input--required bss-text-input--label-uppercase',
            labelClass: 'bss-label--step-navigation-description',
            inputAttributes: {
                maxlength: 60,
                'data-rule-required': true,
                'data-rule-regular': true,
                'data-rule-match': true,
                'data-error-required': 'email-error-required',
                'data-error-regular': 'email-error-invalid',
                'data-error-match': 'email-match-invalid',
                'data-match_to_container': '#textInput_emailConfirmation_text-input',
                'data-errorMatch': 'textInput_emailConfirmation_text-input',
                'data-regular': '^(([A-Za-z0-9@_.-]+_+)|([A-Za-z0-9@_.-]+\\-+)|([A-Za-z0-9@_.-]+\\.+)|([A-Za-z0-9@_.-]+\\++))*[_A-Za-z0-9@_.-]+@((\\w+\\-+)|(\\w+\\.))*\\w{1,63}\\.[a-zA-Z]{2,6}$',
                name: 'email',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'email-error-required',
                    text: "<value data-text='general.error_empty_email'></value>",
                },
                {
                    id: 'email-error-invalid',
                    text: "<value data-text='general.error_invalid_email'></value>",
                },
                {
                    id: 'email-match-invalid',
                    text: "<value data-text='general.error_match_email'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_emailConfirmation',
            testId: 'textInput_emailConfirmation',
            componentType: 'TextInputComponent',
            containerId: 'firstStepFormGrid_col-5',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 8,
            onChangeOff: true,
            required: true,
            label: "<value data-text='general.emailConfirmation'></value>",
            ifDataConvertToLabel: true,
            labelContainerId: 'componentContainer_personalDetailsLabel_content',
            class: 'bss-mb-3 bss-p-0 bss-text-input--required bss-text-input--label-uppercase',
            labelClass: 'bss-label--step-navigation-description bss-watcher-hide',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.emailConfirmation.value'></value>",
            inputAttributes: {
                maxlength: 60,
                'data-rule-required': true,
                'data-rule-regular': true,
                'data-rule-match': true,
                'data-error-required': 'emailConfirmation-error-required',
                'data-error-regular': 'emailConfirmation-error-invalid',
                'data-error-match': 'emailConfirmation-match-invalid',
                'data-match_to_container': '#textInput_email_text-input',
                'data-errorMatch': 'textInput_email_text-input',
                'data-regular': '^(([A-Za-z0-9@_.-]+_+)|([A-Za-z0-9@_.-]+\\-+)|([A-Za-z0-9@_.-]+\\.+)|([A-Za-z0-9@_.-]+\\++))*[_A-Za-z0-9@_.-]+@((\\w+\\-+)|(\\w+\\.))*\\w{1,63}\\.[a-zA-Z]{2,6}$',
                name: 'emailConfirmation',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'emailConfirmation-error-required',
                    text: "<value data-text='general.error_empty_emailConfirmation'></value>",
                },
                {
                    id: 'emailConfirmation-error-invalid',
                    text: "<value data-text='general.error_invalid_emailConfirmation'></value>",
                },
                {
                    id: 'emailConfirmation-match-invalid',
                    text: "<value data-text='general.error_match_email'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_birthDate',
            testId: 'textInput_birthDate',
            componentType: 'TextInputDateComponent',
            containerId: 'firstStepFormGrid_col-6',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 9,
            onChangeOff: true,
            label: "<value data-text='general.birthdayDate'></value>",
            class: 'bss-pt-0 bss-pb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.birthdayDate.value'></value>",
            required: true,
            errorContainer: true,
            errorMessage: "<value data-value='" +
                defaultProductId +
                ".errors.birthdayDate_error'></value>",
            inputAttributes: {
                autocomplete: 'off',
                maxlength: 10,
                'data-rule-required': true,
                'data-error-required': 'birthDate-error-required',
                'data-error-regular': 'birthDate-error-invalid',
                name: 'birthdayDate',
                type: 'text',
                readonly: 'readonly',
            },
            birthdayDate: true,
            dateConfig: {
                viewMode: 'years',
                format: 'DD/MM/YYYY',
                maxDate: '18',
                minDate: '100',
                useCurrent: false,
                ignoreReadonly: true,
            },
            defaultOpenDate: {
                year: '1985',
            },
            inputErrors: [
                {
                    id: 'birthDate-error-required',
                    text: "<value data-text='general.error_empty_birthdayDate'></value>",
                },
                {
                    id: 'birthDate-error-invalid',
                    text: "<value data-text='general.error_invalid_birthdayDate'></value>",
                },
            ],
            maskSettings: '99/99/9999',
            isCalculatePosition: true,
        },
        {
            componentId: 'containerRadioGender_' + defaultProductId,
            testId: 'containerRadioGender',
            componentTemplate: 'componentContainer',
            componentType: 'TemplateComponent',
            containerId: 'firstStepFormGrid_col-7',
            level: 3,
            order: 10,
            class: 'bss-component-container-wrapper--step-navigation bss-mb-3 bss-p-0',
            productId: [defaultProductId, 'general'],
        },
        {
            componentId: 'labelRadioGender_' + defaultProductId,
            testId: 'labelRadioGender',
            containerId: 'containerRadioGender_' + defaultProductId + '_content',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 11,
            text: "<value data-text='general.gender'></value>",
            class: 'bss-label--step-navigation-description bss-mb-2 bss-radio_label-required',
        },
        {
            componentId: 'radioGenderMale_' + defaultProductId,
            testId: 'radioGenderMale',
            containerId: 'containerRadioGender_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 12,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.gender.value'></value>",
            text: "<value data-text='general.genderMale'></value>",
            groupName: 'gender',
            value: 'male',
            class: 'bss-p-0',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-rule-required': true,
                'data-error-required': 'gender-error-required_' + defaultProductId,
            },
        },
        {
            componentId: 'radioGenderFemale_' + defaultProductId,
            testId: 'radioGenderFemale',
            containerId: 'containerRadioGender_' + defaultProductId + '_content',
            componentType: 'RadioButtonComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 13,
            configuration: "<value  data-checked='" +
                defaultProductId +
                ".components.radioButtonComponents.gender.value'></value>",
            text: "<value data-text='general.genderFemale'></value>",
            groupName: 'gender',
            value: 'female',
            class: 'bss-pt-2',
            anotherDesign: true,
            onChangeOff: true,
            radioAttributes: {
                'data-rule-required': true,
                'data-error-required': 'gender-error-required_' + defaultProductId,
            },
        },
        {
            class: 'bss-error-message error-message',
            componentId: 'gender-error-required_' + defaultProductId,
            testId: 'gender-error-required',
            componentType: 'LabelComponent',
            containerId: 'containerRadioGender_' + defaultProductId + '_content',
            level: 3,
            order: 15,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_empty_gender'></value>",
        },
        {
            componentId: 'dropdownMaritialStatus',
            testId: 'dropdownMaritialStatus',
            containerId: 'firstStepFormGrid_col-8',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 14,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.maritialStatus'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'maritialStatus-error-required',
                name: 'maritialStatus',
            },
            selectErrors: [
                {
                    id: 'maritialStatus-error-required',
                    text: "<value data-text='general.error_empty_maritialStatus'></value>",
                },
            ],
            title: "<value data-text='general.maritialStatus'></value>",
            customSelect: true,
        },
        {
            componentId: 'dropdownResidentialStatus',
            testId: 'dropdownResidentialStatus',
            containerId: 'firstStepFormGrid_col-9',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 16,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.residentialStatus'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--full-width bss-dropdown--required bss-dropdown--no-padding bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'residentialStatus-error-required',
                name: 'residentialStatus',
            },
            selectErrors: [
                {
                    id: 'residentialStatus-error-required',
                    text: "<value data-text='general.error_empty_residentialStatus'></value>",
                },
            ],
            title: "<value data-text='general.residentialStatus'></value>",
            customSelect: true,
        },
    ];
}
