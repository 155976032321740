export function gbStep3(_a) {
    var applicationHost = _a.applicationHost, defaultProductId = _a.defaultProductId;
    return [
        {
            componentId: 'titleThirdStep',
            testId: 'titleThirdStep',
            containerId: 'navigationTabsOnlineApproval_tab-content-2',
            componentType: 'LabelComponent',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 1,
            text: "<value data-text='general.employment_details'></value>",
            class: 'bss-label--step-navigation-title',
        },
        {
            componentId: 'thirdStepFormGrid',
            testId: 'thirdStepFormGrid',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-2',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 4,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12', 'bss-col-xs-12 rule-interact-container'],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12'],
                },
            ],
            class: 'bss-p-0 bss-row bss-grid-wrapper--with-postcode-lookup',
        },
        {
            componentId: 'dropdownEmploymentType',
            testId: 'dropdownEmploymentType',
            containerId: 'thirdStepFormGrid_row-0_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 0,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--required bss-mb-3 bss-dropdown--full-width bss-dropdown_label-required bss-dropdown--thick-scrollbar bss-dropdown--form-step-navigation bss-dropdown--change-rules',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'employmentType-error-required',
                name: 'employmentType',
            },
            selectErrors: [
                {
                    id: 'employmentType-error-required',
                    text: "<value data-text='general.error_empty_employmentType'></value>",
                },
            ],
            title: "<value data-text='general.employment_type'></value>",
            customSelect: true,
        },
        {
            componentId: 'dropdownEmploymentStatus',
            testId: 'dropdownEmploymentStatus',
            containerId: 'thirdStepFormGrid_row-0_col-1',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 1,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--required bss-mb-3 bss-dropdown--full-width bss-dropdown_label-required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'employmentStatus-error-required',
                name: 'employmentStatus',
            },
            selectErrors: [
                {
                    id: 'employmentStatus-error-required',
                    text: "<value data-text='general.error_empty_employmentStatus'></value>",
                },
            ],
            title: "<value data-text='general.employment_status'></value>",
            customSelect: true,
        },
        {
            componentId: 'dropdownOccupation',
            testId: 'dropdownOccupation',
            containerId: 'thirdStepFormGrid_row-1_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 2,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.occupation'></value>",
            symbol: '&#xe804',
            class: 'bss-dropdown--required bss-mb-3 bss-dropdown--full-width bss-dropdown_label-required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'occupation-error-required',
                name: 'occupation',
            },
            selectErrors: [
                {
                    id: 'occupation-error-required',
                    text: "<value data-text='general.error_empty_occupation'></value>",
                },
            ],
            title: "<value data-text='general.occupation'></value>",
            customSelect: true,
        },
        {
            componentId: 'textInput_jobTitle',
            testId: 'textInput_jobTitle',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepFormGrid_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 3,
            onChangeOff: true,
            label: "<value data-text='general.job_title'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle.value'></value>",
            required: true,
            class: 'bss-mb-3 bss-text-input--label-uppercase bss-text-input--required',
            inputAttributes: {
                maxlength: 40,
                'data-rule-required': true,
                'data-error-required': 'jobTitle-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]{2,}$',
                'data-rule-regular': true,
                'data-error-regular': 'jobTitle-error-invalid',
                name: 'jobTitle',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'jobTitle-error-required',
                    text: "<value data-text='general.error_empty_jobTitle'></value>",
                },
                {
                    id: 'jobTitle-error-invalid',
                    text: "<value data-text='general.error_invalid_jobTitle'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_employer',
            testId: 'textInput_employer',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepFormGrid_row-2_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 4,
            onChangeOff: true,
            label: "<value data-text='general.employer'></value>",
            class: 'bss-mb-3 bss-text-input--label-uppercase bss-text-input--required',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 30,
                'data-rule-required': true,
                'data-error-required': 'employer-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]{2,}$',
                'data-rule-regular': true,
                'data-error-regular': 'employer-error-invalid',
                name: 'employer',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employer-error-required',
                    text: "<value data-text='general.error_empty_employer'></value>",
                },
                {
                    id: 'employer-error-invalid',
                    text: "<value data-text='general.error_invalid_employer'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_employmentPostCode',
            testId: 'textInput_employmentPostCode',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepFormGrid_row-2_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.employment_postcode'></value>",
            class: 'bss-text-input--postcode bss-mb-3 bss-text-input--label-uppercase bss-text-input--required',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 8,
                'data-rule-required': true,
                'data-error-required': 'employmentPostCode-error-required',
                'data-rule-regular': true,
                'data-error-regular': 'employmentPostCode-error-invalid',
                'data-regular': '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$',
                name: 'employmentPostCode',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentPostCode-error-required',
                    text: "<value data-text='general.error_empty_employmentPostCode'></value>",
                },
                {
                    id: 'employmentPostCode-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentPostCode'></value>",
                },
            ],
        },
        {
            componentId: 'postCodeGrid',
            testId: 'postCodeGrid',
            componentType: 'TemplateComponent',
            containerId: 'thirdStepFormGrid_row-3_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 7,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 bss-col-md-6 rule-interact-container',
                        'bss-col-xs-12 bss-col-md-6 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
            ],
            class: 'bss-p-0 bss-grid-wrapper--address bss-row',
        },
        {
            componentId: 'buttonEmploymentFindAddress',
            testId: 'buttonEmploymentFindAddress',
            containerId: 'postCodeGrid_row-0_col-0',
            componentType: 'ButtonComponent',
            newDesign: true,
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 2,
            isPublicEvent: true,
            sendEventToServer: false,
            text: "<value data-text='general.employment_find_address'></value>",
            onClickEvent: 'find-dealer',
            class: 'bss-button_btn--primary bss-button--find-address bss-button-wrapper--full-width bss-mb-3',
            addressLookup: {
                url: 'https://' +
                    applicationHost +
                    '/dispatcher/onlineApproval/addressLookup/gb',
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressDataEmployment',
                inputDataMap: [
                    {
                        queryName: 'postcode',
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentPostCode',
                        required: true,
                    },
                ],
                outputDataMap: [
                    {
                        responseName: 'postcode',
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentPostCode',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentAddress1AddressLine2',
                        responseName: 'address2',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentTown',
                        responseName: 'town',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentCounty',
                        responseName: 'county',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentAddress1AddressLine1',
                        responseName: 'address1',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentYears',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentMonths',
                        editable: true,
                    },
                ],
            },
        },
        {
            componentId: 'dropdownAddressDataEmployment',
            testId: 'dropdownAddressDataEmployment',
            containerId: 'postCodeGrid_row-0_col-2',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 3,
            onChangeOff: true,
            required: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.dropdownAddressDataEmployment'></value>",
            symbol: '&#xe804',
            class: 'bss-mb-3 bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'addressData-error-required',
                name: 'addressData',
            },
            selectErrors: [
                {
                    id: 'addressData-error-required',
                    text: "<value data-text='general.error_empty_find_address'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-0-change',
                },
            ],
            title: "<value data-text='general.address_from_lookup'></value>",
            customSelect: true,
            createCustomSelectOnEvent: true,
        },
        {
            class: 'bss-label--step-navigation-description bss-mb-2 bss-mt-2 bss-label--required',
            componentId: 'labelAddressDataEmployment_' + defaultProductId,
            testId: 'labelAddressDataEmployment',
            componentType: 'LabelComponent',
            containerId: 'dropdownAddressDataEmployment_dropdown-label-component',
            level: 5,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.address_from_lookup'></value>",
        },
        {
            componentId: 'textInput_employmentStreet',
            testId: 'textInput_employmentStreet',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGrid_row-0_col-3',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 5,
            onChangeOff: true,
            label: "<value data-text='general.address1'></value>",
            class: 'bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine1.value'></value>",
            required: true,
            inputAttributes: {
                'data-rule-required': true,
                'data-error-required': 'employmentStreet-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentStreet-error-invalid',
                name: 'employmentAddress1AddressLine1',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentStreet-error-required',
                    text: "<value data-text='general.error_empty_employmentStreet'></value>",
                },
                {
                    id: 'employmentStreet-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentStreet'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-0-change',
                },
            ],
        },
        {
            componentId: 'textInput_employmentBuildingNumber',
            testId: 'textInput_employmentBuildingNumber',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGrid_row-0_col-4',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 7,
            onChangeOff: true,
            label: "<value data-text='general.address2'></value>",
            class: 'bss-mb-3 bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress1AddressLine2.value'></value>",
            inputAttributes: {
                name: 'employmentAddress1AddressLine2',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentBuildingNumber-error-invalid',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentBuildingNumber-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentBuildingNumber'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-0-change',
                },
            ],
        },
        {
            componentId: 'textInput_employmentTown',
            testId: 'textInput_employmentTown',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGrid_row-1_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 8,
            onChangeOff: true,
            label: "<value data-text='general.employment_town'></value>",
            class: 'bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 40,
                'data-rule-required': true,
                'data-error-required': 'employmentTown-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentTown-error-invalid',
                name: 'employmentTown',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentTown-error-required',
                    text: "<value data-text='general.error_empty_city'></value>",
                },
                {
                    id: 'employmentTown-error-invalid',
                    text: "<value data-text='general.error_invalid_city'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-0-change',
                },
            ],
        },
        {
            componentId: 'textInput_employmentCounty',
            testId: 'textInput_employmentCounty',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGrid_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 9,
            onChangeOff: true,
            label: "<value data-text='general.employment_county'></value>",
            class: 'bss-mb-3 bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty.value'></value>",
            inputAttributes: {
                maxlength: 40,
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentCounty-error-invalid',
                name: 'employmentCounty',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentCounty-error-invalid',
                    text: "<value data-text='general.error_invalid_county'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-0-change',
                },
            ],
        },
        {
            componentId: 'countryNameEmploymentPostLookup',
            testId: 'countryNameEmploymentPostLookup',
            componentType: 'LabelComponent',
            containerId: 'postCodeGrid_row-2_col-0',
            level: 5,
            order: 6,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.united_kingdom'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'manuallyButtonEmployment',
            testId: 'manuallyButtonEmployment',
            componentType: 'ButtonComponent',
            containerId: 'postCodeGrid_row-0_col-1',
            level: 4,
            order: 10,
            productId: [defaultProductId, 'general'],
            newDesign: true,
            isPublicEvent: true,
            sendEventToServer: false,
            text: "<value data-text='general.manually_enter_your_address'></value>",
            href: '',
            onClickEvent: 'find-dealer',
            focusOnInputId: '#textInput_employmentStreet_text-input',
            class: 'bss-button_btn--secondary bss-mb-3 bss-button-wrapper--full-width',
            addressLookup: {
                manuallyButton: true,
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressDataEmployment',
                parentId: '#postCodeGrid',
                manuallyInputsName: [
                    'employmentAddress1AddressLine2',
                    'employmentAddress1AddressLine1',
                    'employmentTown'
                ]
            },
        },
        {
            componentId: 'thirdStepTimeGrid',
            testId: 'thirdStepTimeGrid',
            componentType: 'TemplateComponent',
            containerId: 'postCodeGrid_row-2_col-2',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 12,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12', 'bss-col-xs-12', 'bss-col-xs-12'],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12', 'bss-col-xs-12'],
                },
            ],
            class: 'bss-row bss-pt-2 bss-pb-0',
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-0-change',
                },
            ],
        },
        {
            componentId: 'titleEmploymentTime',
            testId: 'titleEmploymentTime',
            componentType: 'LabelComponent',
            containerId: 'thirdStepTimeGrid_row-0_col-0',
            level: 5,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.employment_time'></value>",
            class: 'bss-label--required bss-label--step-navigation-description',
        },
        {
            componentId: 'textInput_employmentYears',
            testId: 'textInput_employmentYears',
            class: 'periodValidationYears-3 bss-mb-3 bss-text-input--label-uppercase',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepTimeGrid_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 1,
            onChangeOff: true,
            label: "<value data-text='general.employment_years'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentYears.value'></value>",
            inputAttributes: {
                maxlength: 2,
                name: 'employmentYears',
                type: 'text',
                'data-regular': '^[0-9]*$',
            },
            maskSettings: '9{0,2}',
        },
        {
            componentId: 'textInput_employmentMonths',
            testId: 'textInput_employmentMonths',
            class: 'periodValidationMonths-3 bss-mb-3 bss-text-input--label-uppercase',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepTimeGrid_row-0_col-2',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.employment_months'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentMonths.value'></value>",
            inputAttributes: {
                maxlength: 2,
                'data-regular': '(^$)|^([0-9]|10|11)$',
                'data-regular-max-value': 11,
                'data-rule-regular': true,
                'data-error-regular': 'employmentMonths-error-invalid',
                name: 'employmentMonths',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentMonths-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentMonths'></value>",
                },
            ],
            maskSettings: '9{0,2}',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide',
            componentId: 'employmentPeriodValidationErrorLabel',
            testId: 'employmentPeriodValidationErrorLabel',
            componentType: 'LabelComponent',
            containerId: 'thirdStepTimeGrid_row-1_col-0',
            level: 5,
            order: 3,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_employmentYears'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-zero-value-error-1-3',
                },
            ],
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide',
            componentId: 'employmentPeriodValidationErrorLabelEmpty',
            testId: 'employmentPeriodValidationErrorLabelEmpty',
            componentType: 'LabelComponent',
            containerId: 'thirdStepTimeGrid_row-1_col-1',
            level: 5,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_empty_employmentYears'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-empty-value-error-1-3',
                },
            ],
        },
        {
            componentId: 'thirdStepFormGridPrev',
            testId: 'thirdStepFormGridPrev',
            componentType: 'TemplateComponent',
            containerId: 'navigationTabsOnlineApproval_tab-content-2',
            productId: [defaultProductId, 'general'],
            level: 3,
            order: 4,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12',
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12'],
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-clickOnNextButton-1-tab-3',
                },
            ],
            class: 'bss-watcher-hide bss-pt-2 bss-pb-0 bss-row bss-grid-wrapper--with-postcode-lookup',
        },
        {
            componentId: 'employmentValidationLabel',
            testId: 'employmentValidationLabel',
            componentType: 'LabelComponent',
            containerId: 'thirdStepFormGridPrev_row-0_col-0',
            level: 4,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.employment_title'></value>",
            hintText: "<value data-text='general.employment_description'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'dropdownEmploymentTypePrev',
            testId: 'dropdownEmploymentTypePrev',
            containerId: 'thirdStepFormGridPrev_row-0_col-1',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 1,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.employmentType2'></value>",
            symbol: '&#xe804',
            class: 'bss-mb-3 bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation bss-dropdown--change-rules bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'employmentTypePrev-error-required',
                name: 'employmentType2',
            },
            selectErrors: [
                {
                    id: 'employmentTypePrev-error-required',
                    text: "<value data-text='general.error_empty_employmentType2'></value>",
                },
            ],
            title: "<value data-text='general.employment_type'></value>",
            customSelect: true,
        },
        {
            componentId: 'dropdownEmploymentStatusPrev',
            testId: 'dropdownEmploymentStatusPrev',
            containerId: 'thirdStepFormGridPrev_row-0_col-2',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 2,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.employmentStatus2'></value>",
            symbol: '&#xe804',
            class: 'bss-mb-3 bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'employmentStatusPrev-error-required',
                name: 'employmentStatus2',
            },
            selectErrors: [
                {
                    id: 'employmentStatusPrev-error-required',
                    text: "<value data-text='general.error_empty_employmentStatus2'></value>",
                },
            ],
            title: "<value data-text='general.employment_status'></value>",
            customSelect: true,
        },
        {
            componentId: 'dropdownOccupationPrev',
            testId: 'dropdownOccupationPrev',
            containerId: 'thirdStepFormGridPrev_row-1_col-0',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 3,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.occupation2'></value>",
            symbol: '&#xe804',
            class: 'bss-mb-3 bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'occupationPrev-error-required',
                name: 'occupation2',
            },
            selectErrors: [
                {
                    id: 'occupationPrev-error-required',
                    text: "<value data-text='general.error_empty_occupation2'></value>",
                },
            ],
            title: "<value data-text='general.occupation'></value>",
            customSelect: true,
        },
        {
            componentId: 'textInput_jobTitlePrev',
            testId: 'textInput_jobTitlePrev',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepFormGridPrev_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 4,
            onChangeOff: true,
            label: "<value data-text='general.job_title'></value>",
            class: 'bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.jobTitle2.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 40,
                'data-rule-required': true,
                'data-error-required': 'jobTitlePrev-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]{2,}$',
                'data-rule-regular': true,
                'data-error-regular': 'jobTitlePrev-error-invalid',
                name: 'jobTitle2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'jobTitlePrev-error-required',
                    text: "<value data-text='general.error_empty_jobTitle2'></value>",
                },
                {
                    id: 'jobTitlePrev-error-invalid',
                    text: "<value data-text='general.error_invalid_jobTitle2'></value>",
                },
            ],
        },
        {
            componentId: 'textInput_employerPrev',
            testId: 'textInput_employerPrev',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepFormGridPrev_row-2_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 5,
            onChangeOff: true,
            label: "<value data-text='general.employer'></value>",
            class: 'bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employer2.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 30,
                'data-rule-required': true,
                'data-error-required': 'employerPrev-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]{2,}$',
                'data-rule-regular': true,
                'data-error-regular': 'employerPrev-error-invalid',
                name: 'employer2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employerPrev-error-required',
                    text: "<value data-text='general.error_empty_employer2'></value>",
                },
                {
                    id: 'employerPrev-error-invalid',
                    text: "<value data-text='general.error_invalid_employer2'></value>",
                },
            ],
        },
        {
            class: 'bss-text-input--postcode bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            componentId: 'textInput_employmentPostCodePrev',
            testId: 'textInput_employmentPostCodePrev',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepFormGridPrev_row-2_col-1',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.employment_postcode'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentPostCode2.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 8,
                'data-rule-required': true,
                'data-error-required': 'employmentPostCodePrev-error-required',
                'data-rule-regular': true,
                'data-error-regular': 'employmentPostCodePrev-error-invalid',
                'data-regular': '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$',
                name: 'employmentPostCode2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentPostCodePrev-error-required',
                    text: "<value data-text='general.error_empty_employmentPostCode2'></value>",
                },
                {
                    id: 'employmentPostCodePrev-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentPostCode2'></value>",
                },
            ],
        },
        {
            componentId: 'postCodeGridPrev',
            testId: 'postCodeGridPrev',
            componentType: 'TemplateComponent',
            containerId: 'thirdStepFormGridPrev_row-3_col-0',
            productId: [defaultProductId, 'general'],
            level: 4,
            order: 7,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 bss-col-md-6 rule-interact-container',
                        'bss-col-xs-12 bss-col-md-6 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
                {
                    class: 'oa-form-row',
                    columns: [
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container',
                        'bss-col-xs-12 rule-interact-container hidden rule-interact-container-hidden',
                    ],
                },
            ],
            class: 'bss-row bss-pt-0 bss-grid-wrapper--address ',
        },
        {
            componentId: 'buttonEmploymentFindAddressPrev',
            testId: 'buttonEmploymentFindAddressPrev',
            containerId: 'postCodeGridPrev_row-0_col-0',
            componentType: 'ButtonComponent',
            newDesign: true,
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 2,
            isPublicEvent: true,
            sendEventToServer: false,
            text: "<value data-text='general.employment_find_address'></value>",
            onClickEvent: 'find-dealer',
            class: 'bss-button_btn--primary bss-button-wrapper--full-width bss-mb-3 bss-button--find-address',
            addressLookup: {
                url: 'https://' +
                    applicationHost +
                    '/dispatcher/onlineApproval/addressLookup/gb',
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressDataEmployment2',
                inputDataMap: [
                    {
                        queryName: 'postcode',
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentPostCode2',
                        required: true,
                    },
                ],
                outputDataMap: [
                    {
                        responseName: 'postcode',
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentPostCode2',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentAddress2AddressLine2',
                        responseName: 'address2',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentTown2',
                        responseName: 'town',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentCounty2',
                        responseName: 'county',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentAddress2AddressLine1',
                        responseName: 'address1',
                        editable: false,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentYears2',
                        editable: true,
                    },
                    {
                        dataValueName: defaultProductId +
                            '.components.textInputComponents.employmentMonths2',
                        editable: true,
                    },
                ],
            },
        },
        {
            componentId: 'dropdownAddressDataEmployment2',
            testId: 'dropdownAddressDataEmployment2',
            containerId: 'postCodeGridPrev_row-0_col-2',
            componentType: 'DropdownComponent',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 3,
            onChangeOff: true,
            configuration: "<value data-optionliteral='true' data-dropname='" +
                defaultProductId +
                ".components.dropdownComponents.dropdownAddressDataEmployment2'></value>",
            symbol: '&#xe804',
            class: 'bss-mb-3 bss-dropdown--full-width bss-dropdown--required bss-dropdown--form-step-navigation bss-dropdown--thick-scrollbar',
            selectAttributes: {
                'data-rule-required': true,
                'data-error-required': 'addressData-error-required',
                name: 'addressData',
            },
            selectErrors: [
                {
                    id: 'addressData-error-required',
                    text: "<value data-text='general.error_empty_find_address'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-1-change',
                },
            ],
            title: "<value data-text='general.address_from_lookup'></value>",
            customSelect: true,
            createCustomSelectOnEvent: true,
        },
        {
            class: 'bss-label--step-navigation-description bss-label--required bss-mb-2 bss-mt-2',
            componentId: 'labelAddressDataEmployment2_' + defaultProductId,
            testId: 'labelAddressDataEmployment2',
            componentType: 'LabelComponent',
            containerId: 'dropdownAddressDataEmployment2_dropdown-label-component',
            level: 6,
            order: 1,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.address_from_lookup'></value>",
        },
        {
            componentId: 'textInput_employmentStreetPrev',
            testId: 'textInput_employmentStreetPrev',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGridPrev_row-0_col-3',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 4,
            onChangeOff: true,
            label: "<value data-text='general.address1'></value>",
            class: 'bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine1.value'></value>",
            required: true,
            inputAttributes: {
                'data-rule-required': true,
                'data-error-required': 'employmentStreetPrev-error-required',
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentStreetPrev-error-invalid',
                name: 'employmentAddress2AddressLine1',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentStreetPrev-error-required',
                    text: "<value data-text='general.error_empty_employmentStreet2'></value>",
                },
                {
                    id: 'employmentStreetPrev-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentStreet2'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-1-change',
                },
            ],
        },
        {
            componentId: 'textInput_employmentBuildingNumberPrev',
            testId: 'textInput_employmentBuildingNumberPrev',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGridPrev_row-0_col-4',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 5,
            onChangeOff: true,
            label: "<value data-text='general.address2'></value>",
            class: 'bss-mb-3 bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentAddress2AddressLine2.value'></value>",
            inputAttributes: {
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.,-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentBuildingNumberPrev-error-invalid',
                name: 'employmentAddress2AddressLine2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentBuildingNumberPrev-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentBuildingNumber2'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-1-change',
                },
            ],
        },
        {
            componentId: 'textInput_employmentTownPrev',
            testId: 'textInput_employmentTownPrev',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGridPrev_row-1_col-0',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 6,
            onChangeOff: true,
            label: "<value data-text='general.employment_town'></value>",
            class: 'bss-mb-3 bss-text-input--required bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentTown2.value'></value>",
            required: true,
            inputAttributes: {
                maxlength: 40,
                'data-rule-required': true,
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentTown2-error-invalid',
                'data-error-required': 'employmentTown2-error-required',
                name: 'employmentTown2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentTown2-error-required',
                    text: "<value data-text='general.error_empty_city2'></value>",
                },
                {
                    id: 'employmentTown2-error-invalid',
                    text: "<value data-text='general.error_invalid_city2'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-1-change',
                },
            ],
        },
        {
            componentId: 'textInput_employmentCountyPrev',
            testId: 'textInput_employmentCountyPrev',
            componentType: 'TextInputComponent',
            containerId: 'postCodeGridPrev_row-1_col-1',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 7,
            onChangeOff: true,
            label: "<value data-text='general.employment_county'></value>",
            class: 'bss-mb-3 bss-text-input--label-uppercase',
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentCounty2.value'></value>",
            inputAttributes: {
                maxlength: 40,
                'data-regular': '^[a-zA-Z0-9&apos;’ &@ _.-]*$',
                'data-rule-regular': true,
                'data-error-regular': 'employmentCountyPrev-error-invalid',
                name: 'employmentCounty2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentCountyPrev-error-invalid',
                    text: "<value data-text='general.error_invalid_county2'></value>",
                },
            ],
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-1-change',
                },
            ],
        },
        {
            componentId: 'countryNameEmploymentPostLookupPrev',
            testId: 'countryNameEmploymentPostLookupPrev',
            componentType: 'LabelComponent',
            containerId: 'postCodeGridPrev_row-2_col-0',
            level: 5,
            order: 8,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.united_kingdom'></value>",
            class: 'bss-label--step-navigation-description',
        },
        {
            componentId: 'manuallyButtonEmployment2',
            testId: 'manuallyButtonEmployment2',
            componentType: 'ButtonComponent',
            containerId: 'postCodeGridPrev_row-0_col-1',
            level: 5,
            order: 9,
            productId: [defaultProductId, 'general'],
            newDesign: true,
            isPublicEvent: true,
            sendEventToServer: false,
            class: 'bss-button_btn--secondary bss-button-wrapper--full-width bss-mb-3',
            text: "<value data-text='general.manually_enter_your_address'></value>",
            href: '',
            onClickEvent: 'find-dealer',
            focusOnInputId: '#textInput_employmentStreetPrev_text-input',
            addressLookup: {
                manuallyButton: true,
                cacheDataDropname: defaultProductId +
                    '.components.dropdownComponents.dropdownAddressDataEmployment2',
                parentId: '#postCodeGridPrev',
                manuallyInputsName: [
                    'employmentAddress1AddressLine2',
                    'employmentAddress1AddressLine1',
                    'employmentTown'
                ]
            },
        },
        {
            componentId: 'thirdStepTimeGridPrev',
            testId: 'thirdStepTimeGridPrev',
            componentType: 'TemplateComponent',
            containerId: 'postCodeGridPrev_row-2_col-2',
            productId: [defaultProductId, 'general'],
            level: 5,
            order: 10,
            componentTemplate: 'gridRows',
            rows: [
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12', 'bss-col-xs-12', 'bss-col-xs-12'],
                },
                {
                    class: 'oa-form-row',
                    columns: ['bss-col-xs-12', 'bss-col-xs-12'],
                },
            ],
            class: 'bss-row bss-pt-2 bss-pb-0',
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'hide',
                    effectSuccess: 'show',
                    operandRespond: 'postcodeVal',
                    subscriberName: 'bss-step-navigation-dropdownEmployment-1-change',
                },
            ],
        },
        {
            componentId: 'titleEmploymentTimePrev',
            testId: 'titleEmploymentTimePrev',
            componentType: 'LabelComponent',
            containerId: 'thirdStepTimeGridPrev_row-0_col-0',
            level: 6,
            order: 0,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.employment_time'></value>",
            class: 'bss-label--required bss-label--step-navigation-description',
        },
        {
            componentId: 'textInput_employmentYearsPrev',
            testId: 'textInput_employmentYearsPrev',
            class: 'periodValidationYears-3 bss-mb-3 bss-text-input--label-uppercase',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepTimeGridPrev_row-0_col-1',
            productId: [defaultProductId, 'general'],
            level: 6,
            order: 1,
            onChangeOff: true,
            label: "<value data-text='general.employment_years'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentYears2.value'></value>",
            inputAttributes: {
                maxlength: 2,
                name: 'employmentYears2',
                type: 'text',
                'data-regular': '^[0-9]*$',
            },
            maskSettings: '9{0,2}',
        },
        {
            componentId: 'textInput_employmentMonthsPrev',
            testId: 'textInput_employmentMonthsPrev',
            class: 'periodValidationMonths-3 bss-mb-3 bss-text-input--label-uppercase',
            componentType: 'TextInputComponent',
            containerId: 'thirdStepTimeGridPrev_row-0_col-2',
            productId: [defaultProductId, 'general'],
            level: 6,
            order: 2,
            onChangeOff: true,
            label: "<value data-text='general.employment_months'></value>",
            configuration: "<value data-value='" +
                defaultProductId +
                ".components.textInputComponents.employmentMonths2.value'></value>",
            inputAttributes: {
                maxlength: 2,
                'data-regular': '(^$)|^([0-9]|10|11)$',
                'data-regular-max-value': 11,
                'data-rule-regular': true,
                'data-error-regular': 'employmentMonths2-error-invalid',
                name: 'employmentMonths2',
                type: 'text',
            },
            inputErrors: [
                {
                    id: 'employmentMonths2-error-invalid',
                    text: "<value data-text='general.error_invalid_employmentMonths2'></value>",
                },
            ],
            maskSettings: '9{0,2}',
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide',
            componentId: 'employmentPeriodValidationErrorLabelPrev',
            testId: 'employmentPeriodValidationErrorLabelPrev',
            componentType: 'LabelComponent',
            containerId: 'thirdStepTimeGridPrev_row-1_col-0',
            level: 6,
            order: 3,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_invalid_employmentYears2'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-zero-value-error-2-3',
                },
            ],
        },
        {
            class: 'bss-error-message error-message is-invalid bss-watcher-hide',
            componentId: 'employmentPeriodValidationErrorLabelEmptyPrev',
            testId: 'employmentPeriodValidationErrorLabelEmptyPrev',
            componentType: 'LabelComponent',
            containerId: 'thirdStepTimeGridPrev_row-1_col-1',
            level: 6,
            order: 4,
            productId: [defaultProductId, 'general'],
            text: "<value data-text='general.error_empty_employmentYears2'></value>",
            watcher: [
                {
                    boundDataOperand: 'subscriber',
                    effectFail: 'show',
                    effectSuccess: 'hide',
                    operandRespond: 'valid',
                    subscriberName: 'bss-step-navigation-period-empty-value-error-2-3',
                },
            ],
        },
    ];
}
