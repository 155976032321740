import { OaGbElementChangeEvent } from '../../toyota/core/tagging';
var simulatorName = 'finance-approval';
export function tagging(initialData) {
    return {
        publish: function (ev, resultData) {
            var productId = initialData === null || initialData === void 0 ? void 0 : initialData.currentProductId;
            var flowParams = (resultData || {}).flowParams;
            var _a = flowParams || {}, _b = _a.modelId, modelId = _b === void 0 ? null : _b, _c = _a.modelName, modelName = _c === void 0 ? null : _c;
            initialData.modelId = modelId;
            initialData.modelName = modelName;
            switch (ev) {
                case "bss-step-navigation_".concat(productId, "_#change-tab-0"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-personal-details/monthly"),
                    });
                    break;
                case "bss-step-navigation_".concat(productId, "_#change-tab-1"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-address-details/monthly"),
                    });
                    break;
                case "bss-step-navigation_".concat(productId, "_#change-tab-2"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-employment-details/monthly"),
                    });
                    break;
                case "bss-step-navigation_".concat(productId, "_#change-tab-3"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-dealer-search/monthly"),
                    });
                    break;
                case "bss-step-navigation_".concat(productId, "_#change-tab-4"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-privacy/monthly"),
                    });
                    break;
                case "bss-step-navigation_".concat(productId, "_#change-tab-5"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-summary/monthly"),
                    });
                    break;
                case "bss-step-navigation_".concat(productId, "_#change-tab-6"):
                    OaGbElementChangeEvent({
                        initialData: initialData,
                        eventLabel: "".concat(simulatorName, "-complete/monthly/").concat(resultData.proposalStatus)
                    });
                    break;
            }
        },
        init: function (resultData) {
            var flowParams = (resultData || {}).flowParams;
            var _a = flowParams || {}, _b = _a.modelId, modelId = _b === void 0 ? null : _b, _c = _a.modelName, modelName = _c === void 0 ? null : _c;
            initialData.modelId = modelId;
            initialData.modelName = modelName;
            OaGbElementChangeEvent({
                initialData: initialData,
                eventLabel: "".concat(simulatorName, "-personal-details/monthly"),
            });
        },
    };
}
